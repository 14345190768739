import React from "react";


class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/woordenlijst/">Woordenlijst</a>
                    </li>
                    <li className="last">
                      <a href="/veelgestelde-vragen-diarree/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li
                      id="uilist-hor nav-top nav-lang_nl-BE"
                      className="active"
                    >
                      
                    </li>
                    
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="edb89aa4833401bbff915f32b319f6488f9493f1"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/zoeken"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <h1 className="logo" style={{
                "margin-left": "3px"
              }}><a href="/">Imodium®</a>
                </h1>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/hoe-werkt-imodium-instant/">
                          Hoe werkt
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li className="parent-active" id="nav-sub-5">
                        <a href="/imodium-behandelen-diarree/">
                          IMODIUM® <br />
                          Geneesmiddelen
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/feiten-over-diarree/">
                          Diarree - oorzaken
                          <br />
                          en behandeling
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/diarree-op-reis/">
                          Diarree
                          <br />
                          op reis
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/diarree-bij-kinderen/">
                          Diarree
                          <br />
                          bij kinderen
                        </a>
                      </li>
                      <li className="last" id="nav-sub-10">
                        <a href="/infomateriaal-diaree/">Infomateriaal</a>
                      </li>
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/imodium-behandelen-diarree/">
                  {" "}
                  IMODIUM® Geneesmiddelen{" "}
                </a>
              </li>
              <li>
                <a href="/imodium-behandelen-diarree/imodium-instant/">
                  IMODIUM® Instant
                </a>
              </li>
              <li>
                <a href="/imodium-behandelen-diarree/imodium-duo/">
                  IMODIUM® Duo
                </a>
              </li>
              <li>
                <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">
                  IMODIUM® Capsules
                </a>
              </li>
              
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Hoe werkt IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/widget.jpeg"
                    width="170"
                    height="102"
                    alt="Hoe werkt IMODIUM®?"
                  />
                </div>
                <p>
                  Deze video laat zien hoe Imodium<sup>®</sup> helpt om je
                  darmen terug in hun natuurlijke balans te brengen.
                </p>
                <a href="/hoe-werkt-imodium-instant/">Meer informatie</a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-52">
            <h2>
              Welke IMODIUM<sup>®</sup> is geschikt voor jou
            </h2>
            <h3>
              Welke IMODIUM<sup>®</sup> kiezen voor de symptomatische
              behandeling van diarree?
            </h3>
            <div className="enquete">
              <span className="arrow">&nbsp;</span>
              <div className="flexslider questions">
                <ul className="slides">
                  <li className="question">
                    <p className="question-nmbr">
                      <strong>Vraag 1/3:</strong>
                    </p>
                    <p>
                      <i>
                        Gaat je diarree gepaard met een van de volgende
                        symptomen?
                      </i>
                    </p>
                    <form method="post" action className="custom">
                      <p className="error">
                        Gelieve de vraag te beantwoorden, vooraleer je verder
                        gaat.
                      </p>
                      <label htmlFor="radio1">
                        <input
                          name="check01"
                          type="radio"
                          id="radio1"
                          defaultValue="1"
                          className="question1"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Opgezette buik
                      </label>
                      <label htmlFor="radio2">
                        <input
                          name="check01"
                          type="radio"
                          id="radio2"
                          defaultValue="2"
                          className="question1"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Krampen
                      </label>
                      <label htmlFor="radio3">
                        <input
                          name="check01"
                          type="radio"
                          id="radio3"
                          defaultValue="3"
                          className="question1"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Winderigheid
                      </label>
                      <label htmlFor="radio4">
                        <input
                          name="check01"
                          type="radio"
                          id="radio4"
                          defaultValue="4"
                          className="question1"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Geen van bovenstaande
                      </label>
                      <div className="cf">
                        <a
                          href="#"
                          className="radius button right next-question"
                        >
                          Volgende vraag
                        </a>
                      </div>
                    </form>
                  </li>
                  <li className="question">
                    <p className="question-nmbr">
                      <strong>Vraag 2/3:</strong>
                    </p>
                    <p>
                      <i>
                        Ben je op zoek naar een snelwerkend middel tegen
                        diarree?
                      </i>
                    </p>
                    <form method="post" action className="custom">
                      <p className="error">
                        Gelieve de vraag te beantwoorden, vooraleer je verder
                        gaat.
                      </p>
                      <label htmlFor="radio7">
                        <input
                          name="check3"
                          type="radio"
                          id="radio7"
                          defaultValue="1"
                          className="question3"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Ja
                      </label>
                      <label htmlFor="radio8">
                        <input
                          name="check3"
                          type="radio"
                          id="radio8"
                          defaultValue="2"
                          className="question3"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Neen
                      </label>
                      <div className="cf">
                        <a href="#" className="left previous-question">
                          Terug
                        </a>
                        <a
                          href="#"
                          className="radius button right next-question"
                        >
                          Volgende vraag
                        </a>
                      </div>
                    </form>
                  </li>
                  <li className="question">
                    <p className="question-nmbr">
                      <strong>Vraag 3/3:</strong>
                    </p>
                    <p>
                      <i>Vind je het moeilijk om pillen te slikken?</i>
                    </p>
                    <form method="post" action className="custom">
                      <p className="error">
                        Gelieve de vraag te beantwoorden, vooraleer je verder
                        gaat.
                      </p>
                      <label htmlFor="radio9">
                        <input
                          name="check4"
                          type="radio"
                          id="radio9"
                          defaultValue="1"
                          className="question4"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Ja
                      </label>
                      <label htmlFor="radio10">
                        <input
                          name="check4"
                          type="radio"
                          id="radio10"
                          defaultValue="2"
                          className="question4"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Neen
                      </label>
                      <div className="cf">
                        <a href="#" className="left previous-question">
                          Terug
                        </a>
                        <a
                          href="#"
                          className="radius button right next-question"
                        >
                          Antwoorden
                        </a>
                      </div>
                    </form>
                  </li>
                  <li className="question">
                    <div className="results">
                      <div className="result result-lingual">
                        <div className="headline">
                          <p className="question-nmbr">
                            <strong>Antwoord</strong>
                          </p>
                          <h3>
                            IMODIUM<sup>®</sup> Instant
                          </h3>
                          <p>
                            IMODIUM<sup>®</sup> instant smelttablet bestaat als
                            verpakking met 20 of 60 orodispergeerbare tabletten.
                            Het middel is in de apotheek zonder voorschrift te
                            verkrijgen.
                          </p>
                        </div>
                        <div className="media media-flipped">
                          <div className="img">
                            <img
                              alt="IMODIUM®  Instant (loperamide) handige hulp bij behandeling diarree en reizigersdiarree"
                              src="/assets/files/products/images/Imodium_Instant_2mg_20_tabs_3D_Frontal_NL.png"
                              style={{
                                width: "244px",
                                height: "135px"
                              }}
                            />
                          </div>
                          <div className="bd">
                            <ul>
                              <li>Smelt in seconden op de tong</li>
                              <li>Makkelijk zonder water in te nemen</li>
                            </ul>
                            <a
                              className="button radius"
                              href="/imodium-behandelen-diarree/imodium-instant/"
                            >
                              Naar product
                            </a>
                            <br />
                            <a className="next-question" href="#">
                              Test herhalen
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="result result-duo">
                        <div className="headline">
                          <p className="question-nmbr">
                            <b>Antwoord</b>
                          </p>
                          <h3>
                            IMODIUM<sup>®</sup> Duo
                          </h3>
                          <p>
                            IMODIUM<sup>®</sup> Duo bestaat als verpakking met
                            18 capsulevormige tabletten. Het middel is in de
                            apotheek zonder voorschrift te verkrijgen.
                          </p>
                        </div>
                        <div className="media media-flipped">
                          <div className="img">
                            <img
                              alt="IMODIUM®  Duo (loperamide + simeticon) bij behandeling diarree met pijnlijke krampen"
                              src="/assets/files/products/images/ImodiumDuo_18capl_3D_Frontal_NL.PNG"
                              style={{
                                width: "244px",
                                height: "187px"
                              }}
                            />
                          </div>
                          <div className="bd">
                            <ul>
                              <li>
                                Werkt bijzonder snel bij diarree gepaard met
                                krampen.
                              </li>
                              <li>Makkelijk te slikken tabletten</li>
                            </ul>
                            <a
                              className="button radius"
                              href="/imodium-behandelen-diarree/imodium-duo/"
                            >
                              Naar product
                            </a>
                            <br />
                            <a className="next-question" href="#">
                              Test herhalen
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="result result-kapseln">
                        <div className="headline">
                          <p className="question-nmbr">
                            <b>Antwoord</b>
                          </p>
                          <h3>
                            IMODIUM<sup>®</sup> Capsules
                          </h3>
                          <p>
                            IMODIUM<sup>®</sup> Capsules bestaat als verpakking
                            met 20, 60 of 200 capsules. Het middel is in de
                            apotheek zonder voorschrift te verkrijgen.
                          </p>
                        </div>
                        <div className="media media-flipped">
                          <div className="img">
                            <img
                              alt="IMODIUM®  Capsules (loperamide) bij behandeling diarree en reizigersdiarree"
                              src="/assets/files/products/images/Imodium_2mg_20_caps_3D_Frontal_NL.png"
                              style={{
                                width: "244px",
                                height: "182px"
                              }}
                            />
                          </div>
                          <div className="bd">
                            <ul>
                              <li>De klassieker bij diarree</li>
                              <li>
                                Al meer dan 30 jaar werkzaam tegen diarree
                              </li>
                              <li>Makkelijk te slikken capsules</li>
                            </ul>
                            <a
                              className="button radius"
                              href="/imodium-behandelen-diarree/imodiumreg-capsules/"
                            >
                              Naar product
                            </a>
                            <br />
                            <a className="next-question" href="#">
                              Test herhalen
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="row preview">
                <div className="four columns prod-small">
                  <div className="img">
                    <img
                      src="/assets/files/products/images/Imodium_Instant_2mg_20_tabs_3D_Frontal_NL.png"
                      width="126"
                    />
                  </div>
                  <a href="/imodium-behandelen-diarree/imodium-instant/">
                    IMODIUM® Instant
                  </a>
                </div>
                <div className="four columns prod-small">
                  <div className="img">
                    <img
                      src="/assets/files/products/images/ImodiumDuo_18capl_3D_Frontal_NL.PNG"
                      width="90"
                    />
                  </div>
                  <a href="/imodium-behandelen-diarree/imodium-duo/">
                    IMODIUM® Duo
                  </a>
                </div>
                <div className="four columns prod-small">
                  <div className="img">
                    <img
                      src="/assets/files/products/images/Imodium_2mg_20_caps_3D_Frontal_NL.png"
                      width="94"
                    />
                  </div>
                  <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">
                    IMODIUM®
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer class="footer"
style={{"height": "501.006px"}}>
            <div class="row">
                <div class="three columns" style={{
                
              }}>
                    <ul>
                        <li class="first last" >
                        <a href="/hoe-werkt-imodium-instant/">Hoe werkt IMODIUM®?</a>
              
                        <ul>
                        <li class="first last">
                        <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">De werkzame stof: loperamide
                        </a>
                        </li>
                        </ul>
                        </li>
                    </ul>

                    <ul>
                        <li class="first last" >
                        <a href="/imodium-behandelen-diarree/">IMODIUM® Geneesmiddelen</a>
              
                          <ul>
                          <li class="first">
                          <a href="/imodium-behandelen-diarree/imodium-instant/">IMODIUM® Instant
                          </a></li>
                          <li>
                            <a href="/imodium-behandelen-diarree/imodium-duo/">IMODIUM® Duo
                          </a></li>
                          <li class="last">
                            <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">IMODIUM® Capsules
                          </a></li>
                          
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>
                        <li class="first last" >
                        <a href="/feiten-over-diarree/">Feiten over diarree</a>
              
                        <ul>
                        <li class="first"><a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">Tips voor behandeling</a></li>
                        <li><a href="/feiten-over-diarree/oorzaken-van-diarree/">Oorzaken van diarree</a></li>
                        <li><a href="/feiten-over-diarree/stress-en-diarree/">Stress en diarree</a></li>
                        <li><a href="/feiten-over-diarree/buikgriep/">Virale of bacteriële infectie</a></li>
                        <li><a href="/feiten-over-diarree/norovirus/">Norovirus</a></li>
                        <li><a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">Diarree tijdens de menstruatie</a></li>
                        <li><a href="/feiten-over-diarree/chronische-diarree/">Chronische Diarree</a></li>
                        <li><a href="/feiten-over-diarree/eenartsraadplegen/">Een	arts	raadplegen?</a></li>
                        <li class="last"><a href="/feiten-over-diarree/gezonde-eetgewoonten/">Gezonde eetgewoonten</a></li>
                        </ul>
                        </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>                        
                    </ul>
                    <ul>
                        <li class="first last" ><a href="/diarree-op-reis/">Diarree op reis</a>
                <ul>
                          <li class="first"><a href="/diarree-op-reis/reizigersdiaree-vermijden/">Reizigersdiarree vermijden</a></li>
                          <li><a href="/diarree-op-reis/behandeling-van-reizigersdiarree/">Reizigersdiarree behandelen</a></li>
                          <li><a href="/diarree-op-reis/reisapotheek/">Reisapotheek</a></li>
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" >
                    
                    <ul>
                        <li class="first"><a href="/diarree-bij-kinderen/">Diarree bij kinderen</a></li>
                        <li><a href="/infomateriaal-diaree/">Infomateriaal</a></li>
                        <li><a href="/woordenlijst/">Woordenlijst diarree</a></li>
                        <li class="last"><a href="/veelgestelde-vragen-diarree/">FAQ</a></li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="twelve columns">
                    <nav class="nav">
                        <ol class="uilist-hor">
                            <li class="first"><a href="/sitemap/">Sitemap</a></li>
                            <li><a href="/wettelijke-verklaring/">Wettelijke verklaring</a></li>
                            <li><a href="/privacybeleid/">Privacybeleid</a></li>
                            <li><a href="/cookiebeleid/">Cookiebeleid</a></li>
                            <li class="last"><a href="/contact/">Contact</a></li>
                            <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">Cookie-instellingen</a>


                        </ol>
                    </nav>
                </div>
            </div>
            <div class="disclaimer">
                <div class="row">
                    <div class="twelve columns">
                        <p>© Johnson &amp; Johnson Consumer N.V. 2017, BTW BE 0401.955.033 - RPR Antwerpen, 2017. Deze site wordt onderhouden door Johnson &amp; Johnson Consumer N.V. die als enige verantwoordelijk is voor de inhoud. Deze site is bestemd voor een publiek uit België en G.H.Luxemburg. Last updated: Thursday, April 22, 2021 </p>
                        <p>IMODIUM<sup>®</sup> Instant / Capsules (loperamide) is een geneesmiddel. Niet toedienen aan kinderen jonger dan 6 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        IMODIUM<sup>®</sup> Duo (loperamidehydrochloride + simeticon ) is een geneesmiddel, niet toedienen aan kinderen jonger dan 12 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        Lees aandachtig de bijsluiter. Vraag advies aan uw arts of apotheker. Vergunninghouder: Johnson &amp; Johnson Consumer N.V. Antwerpseweg 15-17, B-2340 Beerse. Dossiernr: BE-IM-2200031.
                        </p>
                   </div>
                </div>
            </div>
        </footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-nl.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
